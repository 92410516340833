import { Component, OnInit } from '@angular/core';
import { IOption } from 'ng-select';
import { ValorReferencia } from 'src/app/models/valorReferencia.model';
import { CargabeneficiariosService, TipoReferenciaService, UsuarioService } from 'src/app/services/service.index';
import { IndicadoresService } from '../../../services/indicadores/indicadores.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-Cargabeneficiarios',
  templateUrl: './Cargabeneficiarios.component.html',
  styleUrls: ['./Cargabeneficiarios.component.css']
})
export class CargabeneficiariosComponent implements OnInit {

  RegionalizacionLista: Array<IOption>;
  indicadoresLista: Array<IOption>;
  fileUploaded: any;
  storeData: any;
  worksheet: any;
  jsonData: any;



  constructor(
    public _indicadoresService: IndicadoresService,
    public _tipoReferenciaService: TipoReferenciaService,
    private cargabeneficiariosService: CargabeneficiariosService,
    public _usuarioService: UsuarioService
  ) {

  }
  IdRegionalizacion: number;
  IdIndicador: number;
 
  convocatoria: string;
  texto: string;
  fecha: Date;
  archivoSeleccionado: string;


  ngOnInit(): void {


    this.cargarIndicadores();
  
  }



  CargarInformacion() {


    if (this.IdIndicador && this.IdRegionalizacion) {
      this.readAsJson();
      this.cargabeneficiariosService.cargarBeneficiarios(this.IdIndicador,this.IdRegionalizacion, this.jsonData)
      
    } else {
      
      alert('Por favor, complete todos los campos obligatorios.');
    }
    
  }

  onFileSelected(event: any) {
    
    const file: File = event.target.files[0];
    if (file) {
      
      this.convertirArchivoBase64(file);

     
    }
  }

  convertirArchivoBase64(archivo: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const resultadoBase64 = reader.result as string;
        // Eliminar el encabezado de la URL de datos
//        const base64SinEncabezado = resultadoBase64.split(',')[1];
        this.archivoSeleccionado = resultadoBase64;
        resolve(resultadoBase64);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(archivo);
    });
  }
  


  cargarIndicadores() {
      this._indicadoresService.cargarIndicadores().subscribe((resp: any) => {
      this.indicadoresLista = resp.Lista;
      
    });
  }


  cargarRegionalizacion() {
    debugger;
    this._indicadoresService.cargarRegionalizacion(this.IdIndicador).subscribe((resp: any) => {
    this.RegionalizacionLista = resp.Lista;
    });
  }


  /////////////////////////////
  //      Cargar Excel       //
  /////////////////////////////

  uploadedFile(event) {
    
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  } 

  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      debugger;
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    }
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  readAsJson() {
    debugger;
    this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
    if (this.jsonData !== null) {
      for (let i = 0; i < this.jsonData.length; i++) {
        this.jsonData[i].IndicadorId = this.IdIndicador;
      }
    }

    console.log(this.jsonData);

  }


}

